.loadMore {
    cursor: pointer;
    
    color: grey;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
}

.loadMore:hover {
    color: white;
}
