
#vac-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 15px;
}

#vac-table img {
    width: 32px;
    height: 32px;
    border: 1px #333 solid;
    margin-right: 20px;
}

.accountRow img {
    width: 32px;
    height: 32px;
    border: 1px #333 solid;
    margin-right: 20px;
}

#vac-table th {
    padding: 8px 16px;
    height: 25px;
    background-color: #19202c;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.vac-table-footer {
    padding: 8px 16px;
    margin-bottom: 32px;
    background-color: #19202c;
}

#vac-table tr {
    background-color: #202938;
    height: 48px;
}

#vac-table td {
    padding: 4px 16px;
}

#vac-table>tbody tr:hover {
    cursor: pointer;
    background-color: #3a4455
}

/* .bans > * {
    margin-left: 10px;
} */

.pagingControl {
    width: 100%;
    background-color: #19202c;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagingControl span {
    line-height: 30px;
}

.pagingControl div {
    height: 100%;
}

.pagingControl button {
    background-color: #202938;
    border: none;
    color: white;
    cursor: pointer;
    height: 100%;
    width: 40px;
}

.pagingControl button:hover {
    background-color: #3a4455;
    cursor: pointer;
}

.pagingControl button:disabled {
    color: #444;
}

.pagingControl button:disabled:hover {
    background-color: #202938;
    cursor: default;
}

.link {
    cursor: pointer;
    color: #aaa;
    transition: all 0.15s ease-in-out;
}

.link:hover {
    color: white;
}