.dropdown-wrapper {
    position: relative;
    z-index: 1;
}

.dropdown-wrapper .toggle:hover {
    cursor: pointer;
}

.dropdown-wrapper .dropdown {
    background-color: #202938;
    width: 150px;
    position: absolute;
    right: 0;
    /* box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); */
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}

.dropdown .option {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 8px 16px;
    height: 30px;
}

.dropdown .option:hover {
    cursor: pointer;
    background-color: #3a4455
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.0;
}