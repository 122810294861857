
.stat-table {
    width: 100%;
    border-collapse: collapse;
    /* display: block; */
}

.stat-table th {
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
    background-color: #19202c;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.stat-table tr {
    background-color: #202938;
}

.stat-table td {
    padding: 10px 12px;
}

.stat-table td > div {
    height: 40px;
}

.stat-table td i {
    padding: 0 5px;
}

.stat-icon {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
