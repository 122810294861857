.shortList {
    display: flex;
    width: 100%;
    margin: 10px 0;
}

.shortList.mobile {
    display: block;
    margin: 0;
}

.shortList .shortList-header {
    color: white;
    background-color: #19202c;
    padding: 8px 16px;
    box-sizing: border-box;
}

.shortList>div {
    width: 50%;
}

.shortList.mobile>div {
    width: 100%;
}

.page {
    background-color: #202938;
    width: 100%;
    margin-bottom: 32px;
}

.page .header {
    color: white;
    background-color: #19202c;
    padding: 8px 16px;
    font-size: 1.5em;
    box-sizing: border-box;
}

.page .container {
    padding: 8px 32px 32px;
}
.container-mobile p {
    padding: 8px;
}

.accountRow {
    padding: 4px 16px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #202938;
}

.accountRow:hover {
    cursor: pointer;
    background-color: #3a4455
}

a {
    text-decoration: underline;
}