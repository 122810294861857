
#showcase {
    display: flex;
    flex-wrap: wrap;
}

#showcase>div {
    flex-grow: 1;
    width: 33%;
    height: 160px;
    display: flex;
    align-content: center;
    justify-content: center;
}

/* The fast way */

#showcase img {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    max-height: 140px;
    margin: 0 auto;
    width: auto;
    height: auto;
    transition: all 0.15s ease-in-out;
    backface-visibility: hidden;
}

/* Transition to showing the bigger shadow on hover */

#showcase img:hover {
    transform: scale(1.025) rotate(0.02deg);
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.6);
}

