.Header.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 0 .5em rgba(0, 0, 0, .5);
    background-color: #273245;
    transition: box-shadow 0.15s ease-in-out;
    z-index: 2;
}


.Header {
    padding: 10px 0;
    margin: 0 0 10px;
    box-sizing: border-box;
}

.Header:hover {
    transition: all 0.15s ease-in-out;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
}

    
/* body:not([data-scroll="0"]) {
    padding-top: 3em;
} */



#search {
    margin: 0;
    border-top-left-radius: 3px;
    height: 30px;
    padding: 0 10px;
    box-shadow: inset 0 2px 2px #aaa;
    border-bottom-left-radius: 3px;
}

#search.active {
    border-bottom-left-radius: 0;
}

.Input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    position: relative;
}

.Input .information {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    top: 30px;
    background-color: wheat;
    font-size: 0.8em;
    padding: 2px 8px;
    transform: scaleY(1);
    transform-origin: top;
    color: #333;
    transition: transform 0.1s ease;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.Input .information.hidden {
    transform: scaleY(0);
}

.Input .information.error {
    background-color: #f71735;
}

.Input .information.success {
    background-color: rgb(121, 153, 5);
}

.Input button {
    margin: 0;
    /* min-width: 90px; */
    border: none;
    color: white;
    background-image: linear-gradient(rgb(121, 153, 5) 5%, rgb(83, 105, 4) 95%);
    padding: 4px 16px;
    cursor: pointer;
    height: 30px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}


