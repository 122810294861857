footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px; 
}

footer.mobile {
    height: 200px;
}

.footer-inner {
    display: flex;
    height: 100%;
}

footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

footer a {
    color: white;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
}

footer a:hover {
    color: white;
}

