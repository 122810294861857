@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #dddddf;
  background-color: #273245;
}

body, form, input, button {
  /* font-family: Consolas, Monaco, Menlo, monospace; */
  font-family: "Segoe UI", Arial, Helvetica, sans-serif
}

h1, h2, h3, h4, h5 {
  color: white;
}

.pageContainer {
  position: relative;
  min-height: 100vh;
}

.contentWrap {
  padding-bottom: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

.contentWrap.mobile {
  padding-bottom: 200px;
}

.container {
  margin: 20px 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

input {
  flex-grow: 1;
  border: none;
}

a {
  color: #eee
}

p {
  padding: 0.5em 0;
  line-height: 1.7;
}

.Legend i {
  padding: 0 10px 0 20px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  font-size: .8em;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.copyToClipboard {
  text-decoration: underline;
  text-decoration-style: dashed;
}

.copyToClipboard:hover {
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .Legend {
    display: none;
  }
}

.ghost-loading {
  background-color: #555;
  animation: 1s infinite alternate ease-out breathing-color;
}

@keyframes breathing-color {
  from {
    background-color: #555;
  }
  to {
    background-color: #aaa;
  }
}