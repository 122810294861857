.bottomRight {
    padding-left: 16px;
    width: 100%;
    position: relative;
}

.bottom {
    display: flex;
    align-items: stretch;
    flex-grow: 1;
}